/*This function is intended for loading report data which is already fetched from the backend into the report setup components*/
import _ from 'lodash';
import parseDateStringIntoDateObj from '../../../../utils/parseDateStringIntoDateObj'
import {etchFormatGroupsForFrontEnd} from "../../../../utils"

const reportDataDateKeys = [  //Keep consistent with et-backend/repositories/reports.js const
    "date",
    "lastrainfalldate",
    "stabilizationdate",
    "inspectionend",
    "sheetdate"
];

const reportDataDirectKeys = [
    "type",
    "status",
    "notes", 
    "createdby",
    "officialsiteid",
    "municipality", 
    "onsitecontact",
    "onsitephone", 
    "inspectionnames", 
    "inspectorphone", 
    "temperature", 
    "soilmoisture", 
    "lastrainfalldepth",
    "currentphase",
    "projectonschedule",
    "erosionplanaccessible",
    "permitcertificateposted",
    "constructionstabilized",
    "areasstabilized",
    "sedimentcontrolsremoved",
    "allbmpsfunctional",
    "planaddressesallissues",
    "planreviewrevisions",
    "planreviewnotes",
    //"verifiedBMPList",
    "phase",
    "phaseHistory",
    "mapCenter",
    "mapZoom",
    "basedonsheets"
];


export default async function etLoadReport({
	employeesList = [], 
	employeeIDs = [], 
	sitesList = [],
    groupsList = [],
    activitiesList = [],
    flexdata = {},
    includeActivities = false,
	loadedReportData = {},
	idreport = null,
}){

    console.log("etLoadReport loadedReportData:", loadedReportData)
    console.log("etLoadReport groupsList:", loadedReportData.groupsList);
    loadedReportData.flexdata = JSON.parse(loadedReportData.flexdata);

    //console.log("activeReportToEdit etLoadReport params:",employeesList, employeeIDs, groupsList, sitesList, activitiesList, includeActivities, loadedReportData, idreport)

	let returnData = {flexdata} //keep the names here consistent with components/Reports/ReportSetup/index.js state variables

    if (loadedReportData.idreport){
        returnData.idreport = loadedReportData.idreport;
    } else {
        return null;
    }

	if (!_.isEmpty(loadedReportData.employeesList) && !_.isEmpty(employeesList) && !_.isEmpty(employeeIDs)){
		returnData.employeesList = loadReportEmployees(employeesList, loadedReportData.employeesList, employeeIDs);
	}

	//if (!_.isEmpty(loadedReportData.groupsList)){

        let loggedBMPs = _.cloneDeep(loadedReportData.groupsList);

        for (var i = 0; i < loggedBMPs.length; i++){
            if (!_.isEmpty(loggedBMPs[i].correctionDocumentation)){
                loggedBMPs[i].conditionDocumentation.date = new Date(loggedBMPs[i].conditionDocumentation.date);
            }
            
            if (!_.isEmpty(loggedBMPs[i].correctionDocumentation)){
                loggedBMPs[i].correctionDocumentation.date = new Date(loggedBMPs[i].correctionDocumentation.date);
            }

            loggedBMPs[i].bmpIndex = i;
        }

		returnData.loggedBMPs = _.cloneDeep(loggedBMPs);
    /*} else {
        returnData.loggedBMPs = []
    }*/
    console.log("etLoadReport verifiedBMPList:", loadedReportData.flexdata.verifiedBMPList)

    returnData.groupsList = etchFormatGroupsForFrontEnd(_.cloneDeep(loadedReportData.flexdata.verifiedBMPList));

    if (loadedReportData.idsite && !_.isEmpty(sitesList)){
        returnData.idsite = loadedReportData.idsite;
        for (var i = 0; i < sitesList.length; i++){
            if (sitesList[i].idsite == loadedReportData.idsite){
                returnData.name = sitesList[i].name;
            }
        }
    }

    if (!_.isEmpty(sitesList)){
        returnData.sitesList = sitesList;
    }

    for (var i = 0; i < reportDataDateKeys.length; i++){
        let key = reportDataDateKeys[i];
        if (loadedReportData[key]){
            returnData[key] = parseDateStringIntoDateObj(loadedReportData[key])
        } else if (loadedReportData.flexdata[key]){
            returnData.flexdata[key] = parseDateStringIntoDateObj(loadedReportData.flexdata[key])
        }
    }

    for (var i = 0; i < reportDataDirectKeys.length; i++){
        let key = reportDataDirectKeys[i];
        if (loadedReportData[key]){
            returnData[key] = loadedReportData[key];
        } else if (loadedReportData.flexdata[key]){
            returnData.flexdata[key] = loadedReportData.flexdata[key];
        }
    }

    returnData.idsite = loadedReportData.idsite;
    returnData.mapImg = loadedReportData.mapImg;

    console.log("etLoadReport final returnData:", returnData);

	return returnData;

}

function loadReportEmployees(passedStateEmployeesList, passedLoadedEmployeesList, passedEmployeeIDs){

	let stateEmployeesList = _.cloneDeep(passedStateEmployeesList);
	let employeeIDs = _.cloneDeep(passedEmployeeIDs);
	let loadedEmployeesList = _.cloneDeep(passedLoadedEmployeesList);

    for (var i = 0; i < stateEmployeesList.length; i++){
        stateEmployeesList[i].selected = false;
    }

    for (var i = 0; i < loadedEmployeesList.length; i++){
        let loadedEmployee = loadedEmployeesList[i];
        
        let loadedEmployeeIndex = employeeIDs.indexOf(loadedEmployee.idemployee);

        if (loadedEmployeeIndex !== -1){
            stateEmployeesList[loadedEmployeeIndex].selected = true;
        }
    }

	return stateEmployeesList;
}


/*function loadReportPracticeActivities(passedStateGroupsList, passedLoadedGroupsList, id){
	let stateGroupsList = _.cloneDeep(passedStateGroupsList);
	let loadedGroupsList = _.cloneDeep(passedLoadedGroupsList);

    console.log("loadReportPracticeActivities stateGroupsList:", stateGroupsList);
    console.log("loadReportPracticeActivities loadedGroupsList:", loadedGroupsList);


	let loadedGroups = loadedGroupsList.map(({name}) => {return name})

    for (var i = 0; i < stateGroupsList.length; i++){

        //First, get the corresponding group from the old report data
        let index = loadedGroups.indexOf(stateGroupsList[i].name);
         console.log("loadReportPracticeActivities groupsList index of " + stateGroupsList[i].name+ ":",index)
        

        //Then, if and only if that group has any entries, iterate through the entries
        if (index !== -1 && loadedGroupsList[index].entries !== undefined){

            if (id == "idpractice"){
                markPractices(stateGroupsList, loadedGroupsList, id, i, index)
        //    } else {
        //        markActivities(stateGroupsList, loadedGroupsList, id, i)
        //    }

        // else {
           // console.log("groupsList setting all in group to false")
        //    for (var j = 0; j < stateGroupsList[i].entries.length; j++){
        //        stateGroupsList[i].entries[j].selected = false;
        //    }
        //}

    }


    return stateGroupsList;

}*/

/*function markPractices(stateGroupsList, loadedGroupsList, id = "idpractice", i, groupIndex){
    //alert("Calling markPractices")
    let loadedEntryIds = loadedGroupsList[groupIndex].entries.map((entry) => {return entry[id]});

    for (var j = 0; j < stateGroupsList[i].entries.length; j++){
        let idToSearch = stateGroupsList[i].entries[j][id];
        let practiceIndex = loadedEntryIds.indexOf(idToSearch);

        if (practiceIndex !== -1){
            let loadedEntry = loadedGroupsList[groupIndex].entries[practiceIndex];
            let entryKeys = Object.keys(loadedEntry);
            console.log("markPractices loadedEntry:", loadedEntry)
            console.log("markPractices entryKeys:", entryKeys)


            for (var k = 0; k < entryKeys.length; k++){
                stateGroupsList[i].entries[j][entryKeys[k]] = loadedEntry[entryKeys[k]];
            }
        }

    }
}

function markActivities(stateGroupsList, loadedGroupsList, id = "idactivity", i){
    //alert("Calling markActivities");
    for (var j = 0; j < stateGroupsList[i].entries.length; j++){
        stateGroupsList[i].entries[j].status = loadedGroupsList[i].entries[j].status;
    }
}*/