import React, {Fragment} from 'react';

import ReportSetup from '../components/Reports/ReportSetup'
import ViewReport from '../components/Reports/ViewReport'
import InspectionReports from '../components/Reports/InspectionReports/InspectionReports'

import ActiveSites from '../components/SiteManagement/ActiveSites'
import NewSite from '../components/SiteManagement/NewSite'
import ImportSites from '../components/SiteManagement/ImportSites'

import WorkUnit from '../components/People/WorkUnitCrew/WorkUnitCrew'
import StakeholderContacts from '../components/People/StakeholderContacts/StakeholderContacts'

import AddGroup from '../components/Library/AddGroup';
import ViewLibrary from '../components/Library/ViewLibrary'

import QuickStart from '../components/QuickStart'

import _ from 'lodash';

const common = "/app"

const displayToAll = ["employee", "admin", "crewleader"]

let panels = new Map([
   

    //Reports Sub-Menu
    
    ['report-setup',{
      path: common+"/report-setup",
      menu: "Reports",
      linkName: "New Report",
      displaySidebar: true,
      showInSidebar: true,
      displayTo: _.cloneDeep(displayToAll),
      showIfNoWorkGroup: false,
      sidebarLinkClickCallback: () => localStorage.removeItem('activeReportToEdit'),
      JSX: (props) => <ReportSetup {... props} />
    }],
    ['inspection-reports',{
      path: common+"/inspection-reports",
      menu: "Reports",
      linkName: "Inspection Reports",
      displaySidebar: true,
      showInSidebar: true,
      displayTo: ["employee", "admin", "crewleader"],
      showIfNoWorkGroup: false,
      JSX: (props) => <InspectionReports {...props} />
    }],
    ['report-view',{
      path: common+"/report-view",
      menu: "Reports",
      linkName: "View Report",
      displaySidebar: true,
      showInSidebar: false,
      displayTo: ["employee", "admin", "crewleader"],
      showIfNoWorkGroup: false,
      JSX: (props) => <ViewReport {...props} />
    }],


    //Sites sub-menu

    ['active-sites', {
      path: common+'/active-sites',
      menu: "Sites",
      linkName: "Active Sites",
      displaySidebar: true,
      displayTo: ["admin"],
      showInSidebar: true,
      showIfNoWorkGroup: false,
      JSX: (props) => <ActiveSites 
          title={"Active Sites"} 
          mode={"activeSites"}
          {...props}
      />
    }],
    ['new-site', {
      path: common+'/new-site',
      menu: "Sites",
      linkName: "New Site",
      displaySidebar: true,
      displayTo: ["admin"],
      showInSidebar: true,
      showIfNoWorkGroup: false,
      JSX: (props) => <NewSite {...props} />
    }],
    ['import-sites', {
      path: common+'/import-sites',
      menu: "Sites",
      linkName: "Import Sites",
      displaySidebar: true,
      displayTo: ["admin"],
      showInSidebar: true,
      showIfNoWorkGroup: false,
      JSX: (props) => <ImportSites {...props} />
    }],
    //Archived Sites placeholder 

    //People sub-menu

    ['workunit-crew', {
      path: common+'/workunit-crew',
      menu: "People",
      linkName: "Work Unit Crew",
      displayTo: ["admin", "crewleader"],
      showInSidebar: true,
      showIfNoWorkGroup: true,
      displaySidebar: true,
      JSX: (props) => <WorkUnit {...props} />
    }],
    ['stakeholder-contacts', {
      path: common+'/stakeholder-contacts',
      menu: "People",
      linkName: "Stakeholder Contacts",
      displaySidebar: true,
      displayTo: ["admin", "crewleader"],
      showInSidebar: true,
      showIfNoWorkGroup: false,
      JSX: (props) => <StakeholderContacts {...props} />
    }],

    //Library sub-menu
    ['master-library-practices', {
      path: common+'/master-library-practices',
      menu: "Library",
      linkName: "Master Library",
      displayTo: ["admin"],
      showInSidebar: true,
      showIfNoWorkGroup: false,
      displaySidebar: true,
      JSX: (props) => <ViewLibrary mode={"practice"} isMasterLibrary={true} isSiteLibrary={false} {...props} />
    }],

    ['master-library-actions', {
      path: common+'/master-library-actions',
      menu: "Library",
      linkName: "CA Collection",
      displayTo: ["admin"],
      showInSidebar: false,
      showIfNoWorkGroup: false,
      displaySidebar: true,
      JSX: (props) => <ViewLibrary mode={"action"} isMasterLibrary={true} isSiteLibrary={false} {...props} />
    }],



    ['library-sites-list', {
      path: common+'/library-sites-list',
      menu: "Library",
      linkName: "Site Libraries",
      displayTo: ["admin", "crewleader"],
      showInSidebar: true,
      showIfNoWorkGroup: false,
      displaySidebar: true,
      JSX: (props) => <ActiveSites 
          title={"Site Libraries"} 
          mode={"siteLibraries"}
          {...props}
      />
    }],

    ['site-library-practices', {
      path: common+'/site-library-practices',
      menu: "Library",
      linkName: "BMP Collection",
      displayTo: ["admin",  "crewleader"],
      showInSidebar: false,
      showIfNoWorkGroup: false,
      displaySidebar: true,
      JSX: (props) => <ViewLibrary mode={"practice"} isMasterLibrary={false} isSiteLibrary={true} {...props} />
    }],
    ['site-library-actions', {
      path: common+'/site-library-actions',
      menu: "Library",
      linkName: "CA Collection",
      displayTo: ["admin",  "crewleader"],
      showInSidebar: false,
      showIfNoWorkGroup: false,
      displaySidebar: true,
      JSX: (props) => <ViewLibrary mode={"action"} isMasterLibrary={false} isSiteLibrary={true} {...props} />
    }],



    ['practice-group-master-setup',{
      path: common+'/practice-group-master-setup',
      menu: "Library",
      linkName: "",
      displayTo: ["admin"],
      showInSidebar: false,
      showIfNoWorkGroup: false,
      displaySidebar: false,
      JSX: (props) => <AddGroup isMasterLibrary={true} isSiteLibrary={false} mode={"practice"} {...props} /> 
    }],
    ['action-group-master-setup', {
      path: common+'/action-group-master-setup',
      menu: "Library",
      linkName: "",
      displayTo: ["admin"],
      showInSidebar: false,
      showIfNoWorkGroup: false,
      displaySidebar: false,
      JSX: (props) => <AddGroup isMasterLibrary={true} isSiteLibrary={false} mode={"action"} {...props} />
    }],

    ['practice-group-site-setup',{
      path: common+'/practice-group-site-setup',
      menu: "Library",
      linkName: "",
      displayTo: ["admin", "crewleader"],
      showInSidebar: false,
      showIfNoWorkGroup: false,
      displaySidebar: false,
      JSX: (props) => <AddGroup isMasterLibrary={false} isSiteLibrary={true} mode={"practice"} {...props} /> 
    }],
    ['action-group-site-setup', {
      path: common+'/action-group-site-setup',
      menu: "Library",
      linkName: "",
      displayTo: ["admin", "crewleader"],
      showInSidebar: false,
      showIfNoWorkGroup: false,
      displaySidebar: false,
      JSX: (props) => <AddGroup isMasterLibrary={false} isSiteLibrary={true} mode={"action"} {...props} />
    }],

    ['quick-start', {
      path: common+'/quick-start',
      menu: "Library",
      linkName: "Quick Start",
      displayTo: ["admin", "crewleader"],
      showInSidebar: false,
      showIfNoWorkGroup: false,
      displaySidebar: true,
      JSX: (props) => <QuickStart {...props} />
    }]

]);

export default panels;