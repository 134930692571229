import React, {useState, Fragment, useEffect} from 'react'
import _ from 'lodash'

export default function QuickStart({unit, updateWorkGroup}){

    return(<>
        <>
            <div class="col-12 col-md-6 my-5 mx-auto">
                <div class="jumbotron jumbotron-fluid text-center py-8">
                    <div class="container">
                        <h1 class="display-4 mb-2">Welcome to Earth Tight!</h1>
                        <p class="lead fw-700 mb-0">Setup is as easy as 1, 2, 3...</p>
                        <ol class="text-left mx-auto my-6 fw-600 d-inline-block">
                            <li>Invite employees who will follow and participate in daily huddles.</li>
                            <li>Add your sites, or import sites from other Earth Ideal apps.</li>
                            <li>Customize your library for your company's specific needs.</li>
                        </ol>

                        <p className="mb-0"><em><span class="text-info lead fw-700">
                            Simply open the <i className="ti ti-menu"></i> menu in the upper-left to begin!
                        </span></em></p>

                        <p className="mt-2 mb-0">
                            <small>Or visit <a href="https://www.earthideal.com/earth-tight" target="_blank" >our product page</a> to learn more!</small>
                        </p>
                    </div>
                </div>
            </div>
        </>
                        

    </>)

}

