import React, {Component, useState, useEffect, Fragment}  from 'react';
import _ from 'lodash';
import {
   // PracticeActionsService,
    ReportsService,
    PublishSubscribe
   // WorkGroupsService
} from '../../../services'
import panels from '../../../constants/panels'
import {
    addChildToArray,
    removeChildFromArray,
    updateObjectBasedOnKey,
    isJsonString,
    parseIfJSONString
} from '../../../utils'
import { Link } from 'react-router-dom'
import moment from 'moment';
import SuccessPopup from '../../common/Popups'

import {
  isMobileOnly,
  isTablet
} from 'react-device-detect'



export default function RecentReports(props){

    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    let [client, setClient] = useState(null);
    const [idreportToDelete, setIdReportToDelete] = useState(false);

    useEffect(() => {
       loadReports(true);
       setSubscription();
    }, [props.unit])

    useEffect(() => {return closeSubscription}, []);

    const loadReports = async (showLoading) => {
        let {unit} = props;

        if (unit !== null && unit !== undefined){
            console.log("Loading reports with unit", unit)
            if (loading !== showLoading) setLoading(showLoading);
            let loadedReports = await ReportsService.getRecentReports(unit.idworkgroup, unit.role);
            console.log("loading reports loadedreports:", loadedReports);
            setReports(loadedReports);
            setLoading(false);
        }
    }

    let setSubscription = async () => {
        let {unit} = props;

            if (unit !== null && unit !== undefined){
            client = await PublishSubscribe.quickSubscribe(
                "MySites/Sidebar/index.js",
                "ET/reports",
                handleMessage,
                {idworkgroup: props.unit.idworkgroup}
            );

            setClient(client);
        }
    }

    let closeSubscription = () => {
        if (client !== null){
            setClient(PublishSubscribe.closeClient(client));
        }
    }

    let handleMessage = (message) => {
        console.log("AMQ recentReports handleMessage message:", message);
        message = parseIfJSONString(message);

        if (message.type == "remove-report"){
            console.log("recentReports handleMessage removing report")
            setReports(previousState => removeChildFromArray(previousState, message.idreport, "idreport"));
        } else if (message.type == "updated-report"){
            console.log("recentReports handleMessage processing update");
            //setReports(previousState => updateObjectBasedOnKey(previousState, message.data, ["date"], ["asc"]))
            loadReports(false);
        } else if (message.type == "new-report"){
            console.log("recentReports handleMessage processing new report with message", message);
            loadReports(false);
            //setReports(previousState => addChildToArray(previousState, message.report, "idreport", ["date"], ["asc"]))
        }
    }

    const goToEditReport = async (idreport, status) => {
        await localStorage.setItem("activeReportToEdit", JSON.stringify({idreport, status}));
        props.history.push("/app/report-setup");
    }

    /*const goToViewReport = async (idreport) => {
        await localStorage.setItem("activeReport", idreport);
        props.history.push("/app/report-view");
    }*/

    const deleteReport = async () => {
        let idreport = idreportToDelete;
        setIdReportToDelete(false);
        await ReportsService.deleteReport(idreport);
    }

    const endInspection = async(idreport) => {
        await ReportsService.endReport(idreport);
    }

    const goToNewReport = async () => {
        localStorage.removeItem("activeReportToEdit");
        props.history.push('/app/report-setup')
    }

    const renderSiteName = (isStarted, idreport, siteName) => {
        let className = " strong fs-14" + (!isMobileOnly ? " mb-0" : "");

        return (isStarted ? 
            <a className={"text-dark" + className} href="#" onClick={() => goToEditReport(idreport)}>{siteName}</a>
        :
            <p className={"text-light" + className}>{siteName}</p>
        )
    }

    const renderProgressBar = (isStarted, caStats) => {
        let percentage = caStats.completeCAPercent;

        return <div class="progress">
            <div class={"progress-bar" + ((percentage == 100 && isStarted) ? " progress-bar-striped bg-success" : isStarted ? "" : " progress-bar-striped bg-warning")} role="progressbar" aria-valuemin="0" aria-valuemax="100" style={{width: percentage+'%'}}>
            </div>
        </div>
    }

    const renderLinks = (isStarted, idreport, status, percentage) => {
        return <div class={"text-right flexbox px-0 " + (isMobileOnly ? " pt-4" : isTablet ? " mt-2" : "")}>
            {isStarted ?
                percentage == 100 ?
                    <a className="small text-light" href="#" onClick={() => goToEditReport(idreport, status)}><u>Close Out</u> <i className={"ti ti-timer ml-1" + (!isMobileOnly && !isTablet ? " mr-6" : "")} aria-hidden="true"></i></a>
                :
                    <a className="small text-light" href="#" onClick={() => goToEditReport(idreport, status)}><u>Enter CAs</u> <i className={"ti ti-image ml-1" + (!isMobileOnly && !isTablet ? " mr-6" : "")} aria-hidden="true"></i></a>
            :<>
                <a className="small text-dark" href="#" onClick={() => goToEditReport(idreport, status)}><u>Edit Inspection</u> <i className={"ti ti-pencil-alt ml-1" + (!isMobileOnly && !isTablet ? " mr-6" : "")} aria-hidden="true"></i></a>
                <a className="small text-light" href="#" onClick={() => setIdReportToDelete(idreport)}><i className="ti ti-trash"></i></a>
            </>}
        </div>
    }

    const renderReportRow = (report, key) => {

        let {siteName, idreport, date, status, caStats} = report;
        let isStarted = (!status || status.indexOf("setup") === -1)

        let badgeClassName = "badge " + (isMobileOnly ? "mb-4" : isTablet ? "" : " mt-0")

        console.log("renderReportRow report:", report);

        return <div class="px-0" key={idreport}>
            <div class="card border-bottom">
                <div class="card-body">
                    
                    {isMobileOnly ? renderSiteName(isStarted, idreport, siteName) : null}
                    <div class="flexbox">
                        {!isMobileOnly ? renderSiteName(isStarted, idreport, siteName) : null}
                        
                        <p className={(isTablet ? "mb-0" : "")}>{date ? moment(date).add('hours', -5).format("MMM-DD-YY") : "Date not yet specified"}</p>
                        { status.indexOf("setup") !== -1 ? 
                           <span className={badgeClassName + " badge-pale badge-warning"}>Inspection Pending</span>
                        :
                            caStats.completeCAPercent == 100 ?
                                <span className={badgeClassName + "badge badge-pale badge-success"}>All CAs are complete</span>
                            :
                                <span className={badgeClassName + "badge badge-pale badge-primary"}>Incomplete CAs</span>
                        }

                        {!isMobileOnly ?
                            <div className={"col-3" + (isTablet ? " px-0" : "")}>
                                {renderProgressBar(isStarted, caStats)}
                            </div>
                        :null}

                        {(!isMobileOnly && !isTablet) ? renderLinks(isStarted, idreport, status, caStats.completeCAPercent) : null}

                    </div>
                    {isMobileOnly ?
                        renderProgressBar(isStarted, caStats)
                    : null }
                    
                    
                    {isMobileOnly || isTablet ? renderLinks(isStarted, idreport, status, caStats.completeCAPercent) : null}

                </div>
            </div>
        </div>


    }

    return(<div className="row gap-y px-lg-11">


        <div className="col-12 col-lg-10 mx-auto">
            <div className="card shadow-4">
                <div className="card-body">
                    <div className="row flexbox px-4">
                        <p className="lead-3 mb-2">Pending Inspections</p>
                        <button type="button" className="btn btn-sm btn-primary" onClick={goToNewReport}><i className="ti ti-plus"></i> New Inspection</button>
                    </div>

                    <hr className="mt-2 mb-5" />

                    { loading ? 

                        <div className="loader">Loading...</div>

                    : !_.isEmpty(reports) ?

                        reports.map((report, key) => renderReportRow(report, key))

                    :
                        <p>No inspections currently. <em>Click the <a href="#" onClick={goToNewReport}>+ New Inspection</a> button to begin</em></p>
                    }


                </div>
            </div>
        </div>


        <SuccessPopup
            trigger={idreportToDelete !== false}
            popupId="delete-inspection-popup"
            style="warning"
            useButton={true}
            buttonLabel="Yes"
            title={"Delete Inspection"}
            message={"Are you sure you want to delete this inspection?"}
            callback={deleteReport}
        />


    </div>)
}