import React, {Fragment, useState, useEffect} from 'react';
import { buildElementsFromJSON } from '../../../../utils'
import _ from 'lodash'
import determineBMPStatus from '../utils/determineBMPStatus';
import setActiveBMP from '../utils/setActiveBMP';
import getBMPMarker from '../utils/getBMPMarker';
import identifyIsDocumentationOnlyBMP from '../utils/identifyIsDocumentationOnlyBMP';
import SelectedCAsDisplay from './SelectedCAsDisplay'
import {StatusDisplay} from "./StatusDisplay"
import getBMPImage from "../utils/getBMPImage"
import PhotoModal from '../EntryComponents/generic/PhotoModal';

const defaultThumbnailImage = "../img/thumb/0.png"

export default function LoggedBMPDisplay({handleChange, handleBMPChange, passedBMP, reportState}){

	let [bmp, setBMP] = useState(_.cloneDeep(passedBMP));
	let [status, setStatus] = useState("");
	let [thumbnailImage, setThumbnailImage] = useState(defaultThumbnailImage)
	let [noThumbnailOnLoad, setNoThumbnailOnLoad] = useState(
		(!_.isEmpty(bmp.correctionDocumentation) && _.isEmpty(bmp.correctionDocumentation.photos) )
		&& (!_.isEmpty(bmp.conditionDocumentation) && _.isEmpty(bmp.conditionDocumentation.photos))
	);

	let updateBMPPhoto = (photos) => {
		let cloneBMP = _.cloneDeep(bmp);

		if (!_.isEmpty(cloneBMP.correctionDocumentation)){
			cloneBMP.correctionDocumentation.photos.push(photos)
		} else {
			cloneBMP.correctionDocumentation = {photos}
		}

		if (!_.isEmpty(cloneBMP.conditionDocumentation)){
			cloneBMP.conditionDocumentation.photos.push(photos)
		} else {
			cloneBMP.conditionDocumentation = {photos}
		}

        handleBMPChange(cloneBMP, undefined, bmp.bmpIndex)
        setBMP(cloneBMP)
    }




	useEffect(() => {
		setStatus(determineBMPStatus(bmp, reportState));
	}, [bmp])

	useEffect(() => {
		setThumbnailImage(getBMPImage(bmp));
	}, [status, bmp]);

	let moveOn = () => {
		setActiveBMP(handleChange, reportState, true, bmp.bmpIndex);
		handleChange("currentBMPCorrected", (status != "Complete"))
		handleChange('phase', ('BMPEventSummary' + ((status == "Complete" || identifyIsDocumentationOnlyBMP(bmp)) ? "Complete" : "Incomplete") ));
	}

	let goToEdit = () => {
		setActiveBMP(handleChange, reportState, true, bmp.bmpIndex);
		handleChange("currentBMPCorrected", (status != "Complete"))
		handleChange('phase', 'BMPEventSummaryIncomplete')
	}



	return(<>
		
		<div className="col-11 container border px-0 mb-5" id={`displayed-bmp-${bmp.bmpIndex}`}>
			<div className="card">
				<div className="row no-gutters">
					<div className="col-sm-5">
						<img className="card-img" src={thumbnailImage} alt="Inlet Protection" />
					</div>
					<div className="col-sm-7">
						<div className="card-body">

							<div className="clearfix">
								{getBMPMarker(bmp.letterLabel, status, "list")}
								<p className="card-title lead strong float-left">{bmp.name}</p>
								{/*status != "Complete" ? <button className="btn btn-link float-right" onClick={goToEdit}>✏️</button> : null */}
							</div>

							{!identifyIsDocumentationOnlyBMP(bmp) ? 
								<div className="mb-3">
									<p className="mb-0"><strong>Required Corrective Actions:</strong></p>
									<SelectedCAsDisplay
						        		passedCas={bmp.cas}
						        	/> 
								</div>
							: null}

							<div className="flexbox">

								<StatusDisplay
									status={status}
									onClick={moveOn}
									reportStatus={reportState.status}
								/>

							</div>

							{noThumbnailOnLoad ? <>
								<div className="flexbox">
	 								<button type="button" class="btn btn-round btn-small btn-primary mt-4" data-toggle="modal" data-target={"#add-photos-afterlogging-"+bmp.bmpIndex}>
					                 <i className="fa fa-camera mr-2 mt-n5"></i>
					                    Add Photos
					                </button>
								</div> 

								 <PhotoModal
						            uniqueID={"afterlogging-"+bmp.bmpIndex}
						            capitalizedModeName={"BMP"}
						            callback={(data) => updateBMPPhoto(data)}
						            pictures={bmp ? bmp.conditionDocumentation.photos : null}
						        />

							</> : null}

						</div>
					</div>
				</div>
			</div>
		</div>



	</>)

}