import React, {Fragment} from 'react'
import _ from 'lodash';
import InspectionWrapper from '../../DisplayComponents/generic/InspectionWrapper'
import RadioButtons from  '../../EntryComponents/generic/RadioButtons';
import Checkboxes from  '../../EntryComponents/generic/Checkboxes';
import EmployeeEntry from '../../EntryComponents/EmployeeEntry'
import textLimits from '../../constants/textLimits'

/*Now Unused*/

export default function PlanReview(props){

    let {reportState, saveReportForLater, handleChange, cancel} = props;
    let {flexdata} = reportState;

	return(<>

		<InspectionWrapper
	      title="Plan Review"
	      navButtons={[
	        {
	          className: "btn-secondary", 
	          onClick: () => handleChange('phase', 'BMPVerification'), 
	          label: <><i className="fa fa-angle-left mr-1"></i> BACK</>
	        },
	        {
	          className: "btn-outline-primary",
	          onClick: saveReportForLater,
	          label: "Save for Later"
	        },
	        {
	          className: "btn btn-primary",
	          onClick: () => handleChange("phase", "StabilizationReview"),
	          label: <>CONTINUE <i class="fa fa-angle-right ml-1"></i></>
	        }
	      ]}
	    >


						 	{/*<RadioButtons
	                            name="allbmpsfunctional"
	                            defaultRadioVal={flexdata.allbmpsfunctional}
	                            buttons={[
	                                {label: "Yes", value: "yes"},
	                                {label: "No", value: "no"},
	                            ]}
	                            onChange={(val) => handleChange("allbmpsfunctional", val)}

	                            wrapperClassName="form-group contrast clearfix mr-6 mb-5"
	                            title={<label class="strong fs-16">Are all erosion and sediment control BMPs shown on plan properly installed and in functional condition?</label>}
	                            divClassName="custom-control custom-radio pt-2 pr-4"
	                            labelClassName="custom-control-label fs-14"

	                        />*/}

	                        <RadioButtons
	                            name="planaddressesallissues"
	                            defaultRadioVal={flexdata.planaddressesallissues}
	                            buttons={[
	                                {label: "Yes", value: "yes"},
	                                {label: "No", value: "no"},
	                            ]}
	                            onChange={(val) => handleChange("planaddressesallissues", val)}

	                            wrapperClassName="form-group contrast clearfix mr-6 mb-5"
	                            title={<label class="strong fs-16">Is the plan reflective of current site operations and does it address all erosion and sediment control issues identified during the inspection?</label>}
	                            divClassName="custom-control custom-radio pt-2 pr-4"
	                            labelClassName="custom-control-label fs-14"

	                        />

	                        <div className="form-group contrast clearfix mr-6 mb-1">
		                        <Checkboxes
		                            name="planreviewrevisions"
		                            defaultCheckboxVal={flexdata.planreviewrevisions}
		                            buttons={[
		                               	{label: "Revise sequence", value: "revisesequence"},
		                               	{label: "Revise sediment control BMP", value: "revisesedimentbmp"},
		                               	{label: "Revise erosion control BMP", value: "reviseerosionbmp"},
		                               	{label: "Revise post-construction storm water BMP", value: "revisestormwaterbmp"},
		                            ]}
		                            onChange={(val) => handleChange("planreviewrevisions", val)}

		                            wrapperClassName=""
		                            title={<label class="strong fs-16">Suggest revisions (optional)</label>}
		                            divClassName="custom-control custom-checkbox pt-1"
		                            labelClassName="custom-control-label fs-14"
		                        />


								<input class="form-control mt-4" type="text" placeholder="Notes (optional)"
									value={flexdata.planreviewnotes}
									maxLength={textLimits.textInputCharLimitExtra}
									onChange={(e) => handleChange('planreviewnotes', e.target.value)} 
								/>
							</div>


		</InspectionWrapper>
	</>)
}