import React, {Component, Fragment} from 'react'
import StakeholdersModal from '../../common/StakeholdersModal';
import { url } from '../../../constants'

import moment from 'moment';
import ReactTable from "react-table-6";
import DatePicker from "react-datepicker";
import {
   // PracticeActionsService,
    ReportsService,
    PublishSubscribe
   // WorkGroupsService
} from '../../../services'

import {
    alphanumericSort,
    parseIfJSONString
} from '../../../utils'

import "react-table-6/react-table.css";
import "react-datepicker/dist/react-datepicker.css";
import setDatePickerWidth from '../../../utils/setDatePickerWidth.css'
import { Link } from 'react-router-dom'
import _ from 'lodash'

export default class PastReports extends Component {

	constructor(props){
        super(props);

        this.state = {
            reports: [],
            filter: false,
            sites: [],
            filteredSite: null,
            types: [],
            filteredType: null,
            reportids: [],
            filters: [],
            loading: true,
        }
    }

    componentDidMount = async () => {
        let {unit} = this.props;

        if (unit !== undefined && unit !== null && unit !== false && unit.idworkgroup != 0){
            console.log("PastReports getting reports for unit " + unit.idworkgroup + " from componentDidMount")
            this.getReports(unit, true);
        }
        
    }

    componentWillReceiveProps = async (newProps) => {
        console.log("PastReports calling componentWillReceiveProps with props ", newProps);
    	let {unit} = newProps;
        let oldIdWorkGroup = (this.props.unit ? this.props.unit.idworkgroup : 0);

        if (unit !== undefined && unit !== null && unit !== false && parseInt(unit.idworkgroup) !== parseInt(oldIdWorkGroup)){
            console.log("PastReports getting reports for unit " + unit.idworkgroup + " from componentWillRecieveProps")
            this.getReports(unit, parseInt(unit.idworkgroup) !== parseInt(oldIdWorkGroup));
        }
    	
    }

    updateFilterData = (filteredReports, reportFilters) => {
        console.log("updateFilterData reportFilters:", reportFilters);
        console.log("updateFilterData filteredReports:", filteredReports);

        let reportids = [];
        if (filteredReports && filteredReports.length) reportids = filteredReports.map(({idreport}) => {return idreport});
        let filters = JSON.parse(JSON.stringify(reportFilters)); //wrapped to create a new reference in case the next line mutates reportFilters

        let filteredSite = reportFilters.filter(({id}) => {return id == "idsite"});
        if (filteredSite.length){
            filteredSite = JSON.parse(filteredSite[0].value);
        } else {
            filteredSite = null
        }

        console.log("updateFilterData reportids final:", reportids);
        console.log("updateFilterData filters final:", filters);
        console.log("updateFilterData filteredSite final:", filteredSite);
        
        if (JSON.stringify(reportids) !== JSON.stringify(this.state.reportids) 
            || JSON.stringify(filters) !== JSON.stringify(this.state.filters) 
            || JSON.stringify(filteredSite) !== JSON.stringify(this.state.filteredSite)){
            console.log("updateFilterData updating state");
            this.setState({filteredSite, reportids, filters});
        }
    }


    componentWillUnmount = async () => {
    	//this.client.end();
    }

    /*componentDidUpdate = () => {
        console.log("State update triggered in PastReports")
    }*/

    getReports = async (unit, subscribe) => {
        console.log("Running getReports");
    	let {sites, types} = this.state;
    	let reports = await ReportsService.getPastReports(unit.idworkgroup, unit.role);
    	
        sites = alphanumericSort(await this.addReportSiteToSiteFilter(reports[i], sites), "siteName");

        for (var i = 0; i < reports.length; i++){
            sites = await this.addReportSiteToSiteFilter(reports[i], sites);
            types = await this.addReportTypeToTypeFilter(reports[i], types);
        }

        this.setState({reports, sites, types, loading: false});
        //console.log("List of unique sites ids:",this.siteIds);

        if (subscribe){
            /*this.client = */PublishSubscribe.quickSubscribe(
            "Reports/PastReports",
            "ET/reports",
            this.updateReports,
            {idworkgroup: unit.idworkgroup}
            )
        }
    }

    addReportSiteToSiteFilter = async (report, sites = []) => {

        //console.log("addReportSiteToSiteFilter sites:", sites);
        if (!report) return [];

        let {idsite, siteName} = report

        if (idsite != undefined && idsite != null){
            let index = !_.isEmpty(sites) ? sites.map(site => { return site.idsite }).indexOf(idsite) : -1;

            if (index == -1){
                sites.push({idsite, siteName});
            }
        }

        return sites;
        
    }

    addReportTypeToTypeFilter = async (report, types = []) => {

        //console.log("addReportSiteToSiteFilter sites:", sites);

        let {type} = report

        if (types != undefined && types != null){
            let index = !_.isEmpty(types) ? this.caseInsensitiveIndexOf(type, types)  : -1;

            console.log("addReportTypeToTypeFilter index: " + index, "type", type, "types list", types)

            if (index == -1){
                types.push(type);
            }
        }

        return types;
        
    }

    caseInsensitiveIndexOf = (string, arr) => {

        console.log("calling caseInsensitiveIndexOf with string", string, "and arr", arr)

        let index = arr.indexOf(string);

        console.log("caseInsensitiveIndexOf initial index:", index)

        if (index == -1){
            console.log("caseInsensitiveIndexOf did not find string in initial arr; checking lowercase")
            let lowercaseArr = []
            for (var i = 0; i < arr.length; i++){
                lowercaseArr.push(arr[i].toLowerCase())
            }

            let lowercaseString = string.toLowerCase();

            console.log("caseInsensitiveIndexOf lowercaseArr", lowercaseArr, "lowercaseString", lowercaseString)

            index = lowercaseArr.indexOf(lowercaseString);

            console.log("caseInsensitiveIndexOf new index", index)

        }
        
        return index;

    }

    updateReports = async(report) => {
        console.log("Running updateReports with report ", report);

        report = parseIfJSONString(report);
        let {reports, sites} = this.state;

        if (report.type == "new"){
            reports.unshift(report)
        }

        sites = await this.addReportSiteToSiteFilter(report);

        this.setState({reports, sites});
    }

    reviewReport = async (idreport) => {
        await localStorage.setItem("activeReport", idreport);
        //window.location.replace("localhost:3000");
        this.props.history.push("/app/report-view");
    }

	filterDatesMethod(filter, rows, column){

        const max = filter.value.max ? moment(filter.value.max).hour(23).minute(59).second(59) : moment().add(5, "years");
        const min = filter.value.min ? moment(filter.value.min).hour(0).minute(0).second(0) : moment("2000-01-01");

        //console.log("Filterdates values: min", min, "max", max);

        const result = rows.filter(r => {

            //console.log("filterdates data for ", r);

            if (r[filter.id]){
                let rowDate = moment(r[filter.id]).add('days', 1);
                //console.log("filterdates rowDate", rowDate)
                let greaterThanMin = (rowDate.isSameOrAfter(min));
                //console.log("filterdates greaterThanMin?", greaterThanMin);
                let lessThanMax = (rowDate.isSameOrBefore(max));
                //console.log("filterdates lessThanMax?", lessThanMax);
                return greaterThanMin && lessThanMax;

            } else {
                return false;
            }
        });
        
        return result;
    }

    FilterDates(header){

        let min = header.filter && header.filter.value && header.filter.value.min;
        let max = header.filter && header.filter.value && header.filter.value.max;

        return (
        <div style={{width: "100%", height: "120px"}}>
            Min Date:
            <div  style={{display: "block"}} className="datePicker100Width">
                <DatePicker
                selected={min}
                onChange={date => {
                    header.onChange({min: date, max: max});
                }}
                popperPlacement="bottom-start"
            />
            </div>
            Max Date:
            <div style={{display: "block"}} className="datePicker100Width">
                <DatePicker
                selected={max}
                onChange={date => {
                    header.onChange({max: date, min: min});
                }}
                popperPlacement="bottom-start"
                />
            </div>
        </div>
        );
    }

    renderTime = (time) => {
        let date = new Date(time);
        const hour = ('00' + (date.getHours() % 12 || 12)).substr(-2) + ':' + ('00' + (date.getMinutes())).substr(-2);
        return <span>{hour + (date.getHours() > 11 ? 'PM' : 'AM')}</span>
    }

    filterTextMethod(filter, rows, column){
        const val = filter.value ? filter.value.toString().toLowerCase() : '';
        const vals = val.split(',').map(i => i.trim());
        const result = rows.filter(r => {
            for(var i = 0; i < vals.length; ++i){
                if(filter.id && r[filter.id] && r[filter.id].toString().toLowerCase().search(vals[i]) >= 0){
                    return true
                }
            }
            return false;
            //return r[filter.id] ? r[filter.id].toString().toLowerCase().search(val) >= 0 : false
        })
        return result;
    }

     FilterText(header){
        return (
            <input
                className="mt-2"
                id={header.column.id}
                placeholder='Search'
                value={header.filter ? header.filter.value || "" : ""}
                onChange={e => {
                    header.onChange(e.target.value);
                }}
                style={{width: '100%', textAlign: 'center'}}
            />
        );
    }

    filterSitesSelectMethod(filter, rows, column){
        const val = filter.value ? JSON.parse(filter.value) : "";
        if (val == "") return true;
        let {idsite} = val;

        const result = rows.filter(r => {
            if (filter.id && r[filter.id] && r[filter.id] == idsite){
                return true;
            }
            return false;

        });

        return result;
    }

     FilterSitesSelect(header){

        let {sites} = this.state;
        //console.log("FilterSitesSelect header:", header);


        return (
            <select class="form-control" id={header.column.id} onChange={e => {header.onChange(e.target.value)}}>
                <option value={false}>Select a Site</option>
                {
                    sites.length ? 
                        sites.map((site, key) => {
                            return <option key={site.idsite} value={JSON.stringify(site)}>{site.siteName}</option>
                        })
                    : null
                }
            </select>
        );
    }

    filterTypesMethod(filter, rows, column){
        console.log("filterTypesMethod:", filter.value);
        const val = filter.value ? filter.value + "" : "";
        if (val == "") return true;
        let type = val;

        const result = rows.filter(r => {
            if (filter.id && r[filter.id] && r[filter.id] == type){
                return true;
            }
            return false;

        });

        return result;
    }

     FilterTypesSelect(header){
        let {types} = this.state;

        console.log("types in FilterTypesSelect:", types);

        return (
            <select class="form-control" id={header.column.id} onChange={e => {header.onChange(e.target.value)}}>
                <option value={false}>Select a Type</option>
                {
                    types.length ? 
                        types.map((type, key) => {
                            var text = type;
                            text = text.toLowerCase()
                                .split(' ')
                                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                                .join(' ');
                            return <option key={key} value={type + ""}>{text + ""}</option>
                        })
                    : null
                }
            </select>
        );
    }

    render = () => {
    	let {reports, filter, loading} = this.state;

    	const columns = [
            {
                Header: 'Site Name',
                accessor: 'idsite',
                Cell: ({row}) => {
                	//console.log("sitename row:", row)
                    return <span>{row._original.siteName}</span>
                },
                style: {textAlign: 'center'},
                filterable: filter,
                filterAll: true,
                width: 230,
                filterMethod: this.filterSitesSelectMethod.bind(this),
                Filter: this.FilterSitesSelect.bind(this),
                
            },
            {
                Header: 'Date',
                accessor: 'date',
                Cell: ({row}) => {
                    return <span>{row.date ? moment(row.date).add('hours', -5).format("MM/DD/YY") : "No date specified" }</span>
                },
                style: {textAlign: 'center'},
                filterable: this.state.filter,
                filterAll: true,
                width: 130,
                filterMethod: this.filterDatesMethod.bind(this),
                Filter: this.FilterDates.bind(this),
            },
            {
                Header: 'Type',
                accessor: 'type',
                Cell: ({row}) => {
                    return <span className="text-capitalize">{row.type}</span>
                },
                style: {textAlign: 'left'},
                filterable: this.state.filter,
                filterAll: true,
                width: 130,
                filterMethod: this.filterTypesMethod.bind(this),
                Filter: this.FilterTypesSelect.bind(this),
            },
            {
                Header: 'Report',
                accessor: 'idreport',
                Cell: ({row}) => {
                    return <button type="button" class="btn btn-xs btn-secondary" 
                            onClick={() => this.reviewReport(row.idreport)}>REVIEW
                        </button>
                },
                style: {textAlign: 'center'},
                filterable: false,
                filterAll: true,
                
            },
        ]

        if (loading) {
            return null;
        } else {
             return(<div class="row gap-y px-lg-11 mt-6">

                <div class="col-12 col-lg-10 mx-auto">
                    <div class="card shadow-4">
                        <div class="card-body">
                            <div class="row flexbox px-4">
                                <p class="lead-3 mb-2">Past Reports</p>
                            </div>

                            {!_.isEmpty(this.state.reports) ? <>

                                <hr class="mt-2 mb-5" />

                                <div class="col-12 px-0">
                                    
                                    <div class="text-right float-left pb-4">
                                        <a className="btn btn-secondary" style={this.state.filter ? {backgroundColor: 'deepskyblue', color: 'black'} : {}} onClick={() => {this.setState({filter: !this.state.filter})}}><i class="ti ti-filter mr-1" aria-hidden="true"></i>Filter</a>
                                    </div>
                                    <div class="text-right float-right pb-4">
                                        <button type="button" class="btn btn-sm btn-primary" disabled={this.state.filteredSite === null || this.state.reportids.length == 0} data-toggle="modal" data-target="#stakeholders">
                                            <i class="ti ti-share mr-1" aria-hidden="true"></i>Share
                                        </button>
                                    </div>
                                </div>

                                <ReactTable
                                    data={reports}
                                    columns={columns}
                                    defaultPageSize={10}

                                    className="-striped -highlight table-responsive"
                                    resizable={true}
                                    getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                                    style={{overflowY: 'auto', overflowX: 'visible', zIndex: 0, minHeight: '600px' }}
                                    defaultFiltered={[]}
                                >
                                    
                                        {(state, makeTable, instance) => {
                                           // console.log("ReactTable state:", state);
                                            //console.log("ReactTable instance:", instance)
                                            //console.log("ReactTable state sortedData:", state.sortedData);
                                            //console.log("ReactTable state filtered:", state.filtered);
                                            this.updateFilterData(state.sortedData, state.filtered);
                                           return makeTable();
                                        }}
                                    
                                </ReactTable>
                            </>: _.isEmpty(this.state.reports) ? <>
                                <p><em>Past Reports will appear here after they've been closed out.</em></p>
                            </> : null}


                        </div>
                    </div>
                
                </div>

                 <StakeholdersModal
                    idsite={this.state.filteredSite != null ? this.state.filteredSite.idsite : null}
                    siteName={this.state.filteredSite != null ? this.state.filteredSite.siteName : null}
                    reportsData={{reportids: this.state.reportids, filters: this.state.filters}}
                    pdfMode="multiple"
                />

            </div>)
        }

       
	}


}
