import React from "react";
import { Authenticator, SignIn } from "aws-amplify-react";
import awsconfig from "../../aws-exports";
import CustomLogin from "./customlogin";
import { url } from '../../constants'

//import { Redirect } from 'react-router-dom'

const signUpConfig = {
  header: 'Create an account',
  hideAllDefaults: true,
  defaultCountryCode: '52',
  signUpFields: [
    {
      label: 'Username',
      key: 'username',
      required: true,
      displayOrder: 1,
      type: 'string'
    },
    {
      label: 'Your Name',
      key: 'name',
      required: true,
      displayOrder: 2,
      type: 'string'
    },
    {
      label: 'Work Email address',
      key: 'email',
      required: true,
      displayOrder: 3,
      type: 'email'
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      displayOrder: 4,
      type: 'password'
    },
  ]
};

class Login extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Authenticator
          signUpConfig={signUpConfig}
          //onStateChange={ authState => this.handleStateChanged(authState) }
          hide={[SignIn]}
          amplifyConfig={awsconfig}>
          <CustomLogin
            home = {url.frontend}
            logo="../img/logo/EarthTightLogo.jpeg"
            marketingLink = {"https://www.earthideal.com/earth-tight"}
            appName="Earth Tight"
            marketingTagline = {<span>The Comprehensive <strong>Erosion Control Inspection and Reporting</strong> Tool</span>}
           />
        </Authenticator>
      </div>
      );
  }
}

export default Login;
