import React, {Fragment, useState, useEffect} from 'react'
import _ from 'lodash';
import PhotoModal from '../../EntryComponents/generic/PhotoModal';
import { url } from '../../../../../constants'
import DatePicker from 'react-datepicker'
import textLimits from '../../constants/textLimits'

export default function Document({label, initVal, uniqueID, onChange, includeInitials, valid, textAreaCharLimit}){

   
    let [obj, setObjDirect] = useState(_.cloneDeep(initVal));
    let [showDesc, setShowDesc] = useState(false);

    useEffect(() => {
        setObj("date", new Date())
    }, [])

    useEffect(() => {
        onChange(obj)
    }, [obj])

    let setObj = (key, value) => {
        let cloneObj = {photos: []};
        if (!_.isEmpty(obj)){
            cloneObj = _.cloneDeep(obj);
        }
        cloneObj[key] = value;
        setObjDirect(cloneObj);
    }

	return(<>
		
        <div class="col-12 mt-5 pl-0 pr-5">
            <p class="fs-17"><strong>Document {label}:</strong></p>
            <p className="mb-2">
                <button type="button" class="btn btn-round btn-primary mb-4" data-toggle="modal" data-target={"#add-photos-"+uniqueID}>
                    <i className="fa fa-camera mr-2"></i>
                    Add Photos
                </button>
            </p>
            <p className="mb-0">
                <button type="button" class="btn btn-round btn-outline-primary mb-4" onClick={() => setShowDesc(true)}>
                    <i className="fa fa-clipboard mr-2"></i>
                    Add Description
                </button>
            </p>
            <p>
              {!valid ? 
                    <strong className="text-danger">Description required if no photo</strong>
                : <span>Description required if no photo</span>}
            </p>
        </div>

        <div class="col-12 clearfix px-0">

            {(_.isEmpty(obj) || obj.photos.length == 0) ? null :
                obj.photos.map((image, key) => {
                    return <div class="col-6 float-left" key={key}>
                        <img src={url.backend + "/images/practiceactions/" + image} alt="..." class="img-thumbnail"/>
                    </div>
                })
            }

        </div>  

        {showDesc ? <>
            {includeInitials ? 

                <div class="col-12 my-4 px-4">
                    <div class="form-group flexbox gap-x">
                        <DatePicker
                            className={"form-control"}
                            dateFormat="MM/dd/yyyy hh:mm aa"
                            selected = {obj && obj.date ? obj.date : null}
                            onChange={ date => setObj("date", date) }
                            placeholderText="Date of Completion"
                            showTimeSelect
                        />
                        <input class="form-control" type="text" placeholder="Completed by (initials)"
                            value={obj ? obj.initials : ""} onChange={(e) => setObj('initials', e.target.value)}
                            maxLength={10}
                        />
                    </div>

                    <div class="form-group">
                        <textarea class="form-control" placeholder={"Description of "+label+" (required if no photo)"} rows="3"
                            val={obj ? obj.description : ""}
                            onChange={(e) => setObj("description", e.target.value)}
                            maxLength={/*textLimits.textAreaCharLimit*/ 500}
                        ></textarea>
                    </div>

                </div>
            :

                <div class="col-12 my-4 px-4">
                  <div class="form-group">
                    <DatePicker
                        className={"form-control"}
                        dateFormat="MM/dd/yyyy hh:mm aa"
                        selected = {obj ? obj.date : (new Date())}
                        onChange={ date => setObj("date", date) }
                        placeholderText="Date of Photo"
                        showTimeSelect
                    />
                  </div>

                  <div class="form-group">
                    <textarea class={"form-control" + (!valid ? " is-invalid" : "")} placeholder={"Description of "+label+" (required if no photo)"} rows="3"
                        val={obj ? obj.description : ""}
                        onChange={(e) => setObj("description", e.target.value)}
                        maxLength={/*textLimits.textAreaCharLimit*/ 500}
                    ></textarea>
                    {!valid ? 
                        <span className="text-danger">Description required</span>
                    : null}

                  </div>

                </div>
            }
        </> : null}

        <PhotoModal
            uniqueID={uniqueID}
            capitalizedModeName={label}
            callback={(data) => {setObj('photos', data)}}
            pictures={obj ? obj.photos : null}
        />
        
    </>)
}